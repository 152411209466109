import * as i0 from '@angular/core';
import { EventEmitter, ChangeDetectorRef, ElementRef, Component, ChangeDetectionStrategy, Optional, Self, Inject, Input, HostBinding, Output, NgModule } from '@angular/core';
import * as i4 from '@angular/forms';
import { NgControl, FormsModule } from '@angular/forms';
import { AbstractTuiMultipleControl, TUI_DEFAULT_IDENTITY_MATCHER, ALWAYS_FALSE_HANDLER, TUI_DEFAULT_STRINGIFY, tuiIsNativeFocusedIn } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/kit/components/checkbox-block';
import { TuiCheckboxBlockModule } from '@taiga-ui/kit/components/checkbox-block';
import * as i2 from '@taiga-ui/kit/components/badge';
import { TuiBadgeModule } from '@taiga-ui/kit/components/badge';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i5 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i6 from '@taiga-ui/core';
import { TuiModeModule } from '@taiga-ui/core';

// @bad TODO: Add active zone to track focus
const _c0 = a0 => ({
  $implicit: a0
});
function TuiFilterComponent_tui_checkbox_block_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r4 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r4, " ");
  }
}
function TuiFilterComponent_tui_checkbox_block_0_tui_badge_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-badge", 5);
  }
  if (rf & 2) {
    const badgeValue_r5 = ctx.ngIf;
    const item_r2 = i0.ɵɵnextContext().$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("t-badge_disabled", ctx_r2.disabledItemHandler(item_r2));
    i0.ɵɵproperty("size", ctx_r2.size)("value", badgeValue_r5);
  }
}
function TuiFilterComponent_tui_checkbox_block_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tui-checkbox-block", 1);
    i0.ɵɵlistener("ngModelChange", function TuiFilterComponent_tui_checkbox_block_0_Template_tui_checkbox_block_ngModelChange_0_listener($event) {
      const item_r2 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onCheckbox($event, item_r2));
    });
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵtemplate(2, TuiFilterComponent_tui_checkbox_block_0_ng_container_2_Template, 2, 1, "ng-container", 3)(3, TuiFilterComponent_tui_checkbox_block_0_tui_badge_3_Template, 1, 4, "tui-badge", 4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const item_r2 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r2.disabledItemHandler(item_r2))("hideCheckbox", true)("ngModel", ctx_r2.isCheckboxEnabled(item_r2))("size", ctx_r2.size);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r2.content)("polymorpheusOutletContext", i0.ɵɵpureFunction1(7, _c0, item_r2));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.badgeHandler(item_r2));
  }
}
class TuiFilterComponent extends AbstractTuiMultipleControl {
  constructor(control, cdr, el) {
    super(control, cdr);
    this.el = el;
    this.identityMatcher = TUI_DEFAULT_IDENTITY_MATCHER;
    this.items = [];
    this.size = 'm';
    this.disabledItemHandler = ALWAYS_FALSE_HANDLER;
    this.toggledItem = new EventEmitter();
    this.content = ({
      $implicit
    }) => TUI_DEFAULT_STRINGIFY($implicit);
    this.badgeHandler = item => Number(item);
  }
  get focused() {
    return tuiIsNativeFocusedIn(this.el.nativeElement);
  }
  onCheckbox(value, item) {
    this.toggledItem.emit(item);
    this.value = value ? [...this.value, item] : this.value.filter(arrItem => !this.identityMatcher(arrItem, item));
  }
  isCheckboxEnabled(item) {
    return this.value.some(arrItem => this.identityMatcher(arrItem, item));
  }
}
TuiFilterComponent.ɵfac = function TuiFilterComponent_Factory(t) {
  return new (t || TuiFilterComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(ElementRef));
};
TuiFilterComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiFilterComponent,
  selectors: [["tui-filter"]],
  hostVars: 1,
  hostBindings: function TuiFilterComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  inputs: {
    identityMatcher: "identityMatcher",
    items: "items",
    size: "size",
    disabledItemHandler: "disabledItemHandler",
    content: "content",
    badgeHandler: "badgeHandler"
  },
  outputs: {
    toggledItem: "toggledItem"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 1,
  vars: 1,
  consts: [["automation-id", "tui-filter__checkbox", "class", "t-item", 3, "disabled", "hideCheckbox", "ngModel", "size", "ngModelChange", 4, "ngFor", "ngForOf"], ["automation-id", "tui-filter__checkbox", 1, "t-item", 3, "ngModelChange", "disabled", "hideCheckbox", "ngModel", "size"], ["automation-id", "tui-filter__content", 1, "t-content"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["automation-id", "tui-filter__badge", "tuiMode", "onLight", "class", "t-badge", 3, "t-badge_disabled", "size", "value", 4, "ngIf"], ["automation-id", "tui-filter__badge", "tuiMode", "onLight", 1, "t-badge", 3, "size", "value"]],
  template: function TuiFilterComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiFilterComponent_tui_checkbox_block_0_Template, 4, 9, "tui-checkbox-block", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngForOf", ctx.items);
    }
  },
  dependencies: [i1.TuiCheckboxBlockComponent, i2.TuiBadgeComponent, i3.NgForOf, i4.NgControlStatus, i4.NgModel, i5.PolymorpheusOutletDirective, i3.NgIf, i6.TuiModeDirective],
  styles: ["[_nghost-%COMP%]{display:block;margin-bottom:-.25rem}.t-content[_ngcontent-%COMP%]{display:flex;align-items:center}.t-item[_ngcontent-%COMP%]{margin-right:.25rem;margin-bottom:.25rem}.t-item[_ngcontent-%COMP%]:last-child, [data-size=l][_nghost-%COMP%]   .t-item[_ngcontent-%COMP%]:last-child{margin-right:0}[data-size=l][_nghost-%COMP%]   .t-item[_ngcontent-%COMP%]{margin-right:.5rem;margin-bottom:.5rem}.t-badge[_ngcontent-%COMP%]{margin-left:.25rem}.t-badge_disabled[_ngcontent-%COMP%]{opacity:var(--tui-disabled-opacity)}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFilterComponent, [{
    type: Component,
    args: [{
      selector: 'tui-filter',
      templateUrl: './filter.template.html',
      styleUrls: ['./filter.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: i4.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }];
  }, {
    identityMatcher: [{
      type: Input
    }],
    items: [{
      type: Input
    }],
    size: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.data-size']
    }],
    disabledItemHandler: [{
      type: Input
    }],
    toggledItem: [{
      type: Output
    }],
    content: [{
      type: Input
    }],
    badgeHandler: [{
      type: Input
    }]
  });
})();
class TuiFilterModule {}
TuiFilterModule.ɵfac = function TuiFilterModule_Factory(t) {
  return new (t || TuiFilterModule)();
};
TuiFilterModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiFilterModule
});
TuiFilterModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, FormsModule, PolymorpheusModule, TuiCheckboxBlockModule, TuiBadgeModule, TuiModeModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFilterModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, FormsModule, PolymorpheusModule, TuiCheckboxBlockModule, TuiBadgeModule, TuiModeModule],
      declarations: [TuiFilterComponent],
      exports: [TuiFilterComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFilterComponent, TuiFilterModule };
